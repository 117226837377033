@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-behavior: smooth;
  width: 100%;
  height: 100%;
}

body {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
}

#root {
  width: 100%;
  height: 100%;
}

/* fonts  ******************************************* */
/* Bold Font  */
@font-face {
  font-family: "Avenir_bold";
  src: url("../public/fonts/avenir-next-lt-pro/avenirnextltpro-bold.otf");
}

/* regular font */
@font-face {
  font-family: "Avenir_regular";
  src: url("../public/fonts/avenir-next-lt-pro/avenirnextltpro-regular.otf");
}

/* demi font */
@font-face {
  font-family: "Avenir_demi";
  src: url("../public/fonts/avenir-next-lt-pro/avenirnextltpro-demi.otf");
}

/* demicn font */
@font-face {
  font-family: "Avenir_demicn";
  src: url("../public/fonts/avenir-next-lt-pro/avenirnextltpro-demicn.otf");
}

/* italic font */
@font-face {
  font-family: "Avenir_italic";
  src: url("../public/fonts/avenir-next-lt-pro/avenirnextltpro-it.otf");
}

input {
  caret-color: white;
  color: white !important;
}

input::placeholder {
  color: white !important;
}

/* Ant override Start */

.ant-select {
  background-color: transparent !important;
}

.ant-select-selector {
  background-color: transparent !important;
}

.ant-select-selector::placeholder {
  color: white !important;
}

.ant-select-selector:hover {
  border-color: white !important;
}

.ant-select .ant-select-selector {
  border-radius: 15px;
}

.ant-select-selection-placeholder {
  color: white !important;
}

.ant-select-selection-item {
  color: white !important;
}

.ant-select-focused .ant-select-selector,
.ant-select-selector:focus,
.ant-select-selector:active,
.ant-select-open .ant-select-selector {
  border-color: white !important;
}

.input-variant .ant-input {
  border-color: #9aadbf !important;
}

.ant-input {
  background-color: transparent !important;
  border-color: white !important;
}

.ant-input-affix-wrapper {
  background-color: transparent !important;
  border-color: white !important;
}

.input-variant-ant .ant-input::placeholder {
  color: black !important;
}

.input-variant-ant .ant-input {
  border-color: black !important;
}

.spin-full-width {
  width: 100%;
}

.spin-full-width .ant-spin-container {
  width: 100%;
}

.spin-full-width .ant-select-selection-placeholder {
  color: gray;
}

.spin-full-width .ant-select-selection-item {
  color: black !important;
}

.spin-full-width .ant-select-selector:hover {
  border-color: gray !important;
}

.spin-full-width .ant-select-focused .ant-select-selector,
.ant-select-selector:focus,
.ant-select-selector:active,
.ant-select-open .ant-select-selector {
  border-color: black !important;
}

.dashboard-input .ant-input {
  border-color: #6b7280 !important;
  color: black !important;
  caret-color: black !important;
  border-radius: 15px !important;
}

.dashboard-input .ant-input::placeholder {
  color: #6b7280 !important;
}

.spin-full-width .ant-select-selector {
  min-height: 48px !important;
}

.anticon.anticon-eye.ant-input-password-icon svg {
  fill: white !important;
}

.anticon.anticon-eye-invisible.ant-input-password-icon svg {
  fill: white !important;
}

.black-border .ant-select-selector {
  border-color: black !important;
}

.black-border .ant-select-selection-placeholder {
  color: #6b7280 !important;
}

.ant-picker-input input {
  color: black !important;
}

.ant-picker-input input::placeholder {
  color: rgb(156, 163, 175) !important;
}

.spin-center .ant-spin-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ant-spin-nested-loading.spin-full-height {
  height: 100%;
}

.ant-spin-nested-loading.spin-full-height .ant-spin-container {
  height: 100%;
}

.select-no-border .ant-select-selector {
  border: none !important;
}

.select-white-text .ant-select-selection-item {
  color: white !important;
}

.select-black-text .ant-select-selection-placeholder {
  color: black !important;
}

.select-black-text .ant-select-selection-item {
  color: black !important;
}

.ant-select-selection-search-input {
  color: black !important;
}

/* Ant override End */

/* Scrollbar styles */

.scroll::-webkit-scrollbar {
  width: 5px;
}

.scroll::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  padding-right: 10px;
  overflow: hidden;
}

.scroll::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: #142644;
}

/* ul & ol style fix start */

ul {
  list-style-type: disc;
}

ol {
  list-style-type: decimal;
}

/* ul & ol style fix end */

/* react wysiwyg override start */
.wrapperClassName.rdw-editor-wrapper {
  background-color: white !important;
}
/* react wysiwyg override end */

/* ant match making slider  */
.ant-slider-rail {
  background-color: #e3ff66 !important;
  height: 15px !important;
  border-radius: 10px !important;
}

.ant-slider-track-1 {
  background-color: #142644 !important;
  height: 15px !important;
  border-radius: 10px !important;
}

.ant-slider-handle-1 {
  transform: translate(0%, 30%) !important;
}

.ant-slider-handle::after {
  width: 15px !important;
  height: 15px !important;
}

.ant-slider-handle-2 {
  transform: translate(-157%, 30%) !important;
}

/* login phone number  */
@layer base {
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

/* date picker  */

.ant-picker:hover {
  border-color: white !important;
  background-color: transparent !important;
}

.anticon-calendar {
  color: white !important;
}

.ant-picker-input input {
  color: white !important;
}

.ant-picker-input input::placeholder {
  color: white !important;
}

.anticon-calendar svg {
  width: 20px !important;
  height: 20px !important;
}

.ant-picker-clear svg {
  color: white !important;
  width: 20px !important;
  height: 20px !important;
}

/* .ant-picker-input .ant-picker-suffix:focus {
  background-color: transparent !important;
} */

/* phone input  */
.form-control {
  background-color: transparent !important;
}

.form-control:focus {
  border-color: #ffffff !important;
  box-shadow: 0px 0px 10px -1px #ffffff !important;
}

.invalid-number-message {
  top: 48px !important;
  left: 0px !important;
  background-color: transparent !important;
  font-weight: 600 !important;
}

.facebook-login-button {
  @apply w-full px-10 py-3 flex items-center justify-start text-sm gap-14 font-Avenir_bold bg-[#DCDEE3] rounded-[15px] hover:bg-pro_text_dark hover:text-pro_text_light hover:duration-300
}